<template>
  <UiTourDriver
    ref="driver"
    :tour="TOURS_IDS.ACTIVITY_CREATION_TYPES"
    :highlight
    :type="TOURS_TYPES.UPDATE"
    @destroyed="useMittEvent('tour:activityCreationType')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

onMounted(() => {
  useMittListen('tour:activityCreationType', () => {
    if (driver.value?.driverObj) {
      driver.value.startTour()
    }
  })
})

const highlight = ref<DriveStep>({
  element: '#activity-types',
  popover: {
    title: 'Activity types changed',
    description: `Empower your workflow with <b>Tasks</b> for personalized lead-related activities, and <b>Meetings</b> to seamlessly schedule calls and send invites directly from the CRM.`,
    nextBtnText: "Okay, I'll check!",
    showButtons: ['next'],
    onNextClick: () => {
      if (driver.value?.driverObj) {
        driver.value.driverObj.destroy()
      }
    },
  },
})

onUnmounted(() => {
  useMittRemove('tour:activityCreationType')
})
</script>

<style scoped lang="scss">
:deep() {
  .driver-popover-next-btn {
    width: 100% !important;
  }
}
</style>
